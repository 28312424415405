<script>
    import { toast } from "@zerodevx/svelte-toast";
    import { AccordionItem, Button, Spinner, P } from "flowbite-svelte";
    import {
        FileCopyOutline,
        TrashBinOutline,
        BadgeCheckSolid,
        ExclamationCircleSolid,
    } from "flowbite-svelte-icons";
    import { marked } from "marked";
    import {
        qualify,
        qualify_add_instructions_modal_open,
        qualify_general_instructions,
        selected_module,
        selected_section,
        selected_sub_section,
    } from "../service/store";
    import { ask_llm } from "../service/http_calls";

    export let title = "";
    export let formattedContent = "";
    export let module = "";
    export let section = "";
    export let sub_section = "";
    export let injected_extra_prompt = "";

    let loading = false;

    const handleRegenerate = async () => {
        loading = true;

        let resp = await ask_llm(
            $qualify_general_instructions + "\n\n" +
            injected_extra_prompt +
                $qualify[section][sub_section].user_instructions,
            module,
            section,
            sub_section,
            "gpt-4o",
            true,
        );
        qualify.update((prev) => {
            prev[section][sub_section]["generated_content"] =
                resp.generated_content;
            return prev;
        });
        loading = false;
    };
</script>

<AccordionItem>
    <span slot="header" class="inline-flex items-center mr-10">
        {title}
        {#if section !== "questions"}
            {#if $qualify?.[section]?.[sub_section]?.generated_content
                ?.toLowerCase()
                .includes("no improvement needed") || $qualify?.[section]?.[sub_section]?.generated_content
                    ?.toLowerCase()
                    .includes("no improvement required")}
                <BadgeCheckSolid class="w-5 h-5 ml-2 text-green-500"
                ></BadgeCheckSolid>
            {:else if $qualify?.[section]?.[sub_section]?.generated_content
                ?.toLowerCase()
                .includes("optional improvement")}
                <BadgeCheckSolid class="w-5 h-5 ml-2 text-yellow-500"
                ></BadgeCheckSolid>
            {:else if $qualify?.[section]?.[sub_section]?.generated_content
                ?.toLowerCase()
                .includes("improvement needed") || $qualify?.[section]?.[sub_section]?.generated_content
                    ?.toLowerCase()
                    .includes("improvement required")}
                <ExclamationCircleSolid class="w-5 h-5 ml-2 text-red-500"
                ></ExclamationCircleSolid>
            {/if}
        {/if}
    </span>
    <div class="prose max-w-full">
        <div>
            {#if formattedContent}
                {@html marked(formattedContent)}
            {:else}
                <small>No content</small>
            {/if}
        </div>
    </div>
    {#if title == "Internet Search"}
        <div class="flex justify-center my-10">
            <P size="xs" class="text-gray-800"
                >AI searches only the current Internet and cannot retrieve past
                or historical data.</P
            >
        </div>
    {/if}

    <div class="flex justify-between mt-5">
        <div class="flex justify-start">
            <button
                on:click={() => {
                    if (navigator && navigator.clipboard && formattedContent) {
                        navigator.clipboard.writeText(formattedContent);
                    } else {
                        console.error("Clipboard API not available");
                    }
                    toast.push("Copied to clipboard!");
                }}
                style="background: none; border: none; cursor: pointer;"
                class="mr-5"
            >
                <FileCopyOutline></FileCopyOutline>
            </button>
            <button
                on:click={() => {
                    qualify.update((prev) => {
                        prev[section][sub_section]["generated_content"] = "";
                        return prev;
                    });
                }}
            >
                <TrashBinOutline></TrashBinOutline>
            </button>
        </div>

        <div class="flex justify-end">
            <Button
                size="xs"
                color="alternative"
                class="mr-5"
                on:click={() => {
                    selected_module.set(module);
                    selected_section.set(section);
                    selected_sub_section.set(sub_section);
                    qualify_add_instructions_modal_open.set(true);
                }}>Add Instructions</Button
            >
            {#if loading}
                <Spinner></Spinner>
            {:else}
                <Button
                    size="xs"
                    color="purple"
                    on:click={() => {
                        handleRegenerate();
                    }}>Regenerate</Button
                >
            {/if}
        </div>
    </div>
</AccordionItem>
