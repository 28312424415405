<script>
    import {
        Button,
        Navbar,
        NavBrand,
        NavHamburger,
        NavLi,
        NavUl,
    } from "flowbite-svelte";
    import { UserCircleSolid } from "flowbite-svelte-icons";
    import { authenticated_user, current_user } from "../service/store";
    import { onMount } from "svelte";

    let slug = "";
    onMount(async () => {
        // Extracting the slug from the URL
        const pathParts = window.location.pathname.split("/").filter(Boolean);
        if (pathParts.length > 0) {
            slug = pathParts[pathParts.length - 1];
        }
        console.log(slug);
    });
</script>

<Navbar>
    <NavBrand href="/">
        <span
            class="self-center whitespace-nowrap text-xl font-semibold dark:text-white"
        >
            SmartClaim
        </span>
    </NavBrand>
    <NavHamburger />
    <div class="flex md:order-2">
        <NavUl class="flex-grow" activeUrl={`/${slug}`}>
            {#if $current_user?.user_type === "admin"}
                <NavLi href="/research">Research</NavLi>
                <NavLi href="/qualify"
                    >Qualify</NavLi
                >
                <NavLi href="/drafting">Draft</NavLi>
                <NavLi href="/review">Review</NavLi>
                <NavLi href="/defend"
                    >Defend</NavLi
                >
            {:else}
                <NavLi href="/research">Research</NavLi>
                <NavLi href="/qualify"
                    >Qualify</NavLi
                >
                <NavLi href="/drafting">Draft</NavLi>
                <NavLi href="/review">Review</NavLi>
                <NavLi href="/defend"
                    >Defend</NavLi
                >
            {/if}
        </NavUl>
        {#if $authenticated_user}
            <Button
                pill
                color="alternative"
                size="xs"
                on:click={() => {
                    window.location.href = "/profile";
                }}
            >
                <UserCircleSolid class="w-5 h-5"></UserCircleSolid>
            </Button>
        {:else}
            <Button size="sm" color="blue" href="/early-access" class="mr-5"
                >Get Early Access</Button
            >
        {/if}
    </div>
</Navbar>
