<script>
  import { Button, Heading, P, Span } from "flowbite-svelte";
  import { HeroHeader, Section } from "flowbite-svelte-blocks";
  import { ArrowRightOutline } from "flowbite-svelte-icons";
  import { onMount } from "svelte";
</script>

  <div class="mr-auto place-self-center lg:col-span-7">
    <HeroHeader
      h1Class="max-w-2xl mb-4 text-4xl font-extrabold tracking-tight leading-none md:text-5xl xl:text-6xl dark:text-white"
      pClass="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400"
    >
      <Heading tag="h1" class="mb-4">
        R&D Tax Narratives
        <br />
        <Span gradient>Made Easy</Span></Heading
      >
      <P class="text-2xl mb-10">
        Supercharge efficiency AND robustness with AI
      </P>
        <Button
          size="xl"
          color="light"
          class="inline-flex items-center justify-center mr-3"
          on:click={(e) => {
            e.preventDefault();
            window.location.href = "/login";
          }}
          >Log in<ArrowRightOutline
            size="md"
            class="ml-2 -mr-1"
          /></Button
        >
    </HeroHeader>
  </div>
